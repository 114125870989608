var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.isLoading}},[_c('b-row',[_c('b-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('b-avatar',{staticStyle:{"width":"200px","height":"200px"},attrs:{"src":_vm.uploaded ? _vm.uploaded : '',"variant":"primary","alt":"Avatar"}})],1)],1),_c('ValidationObserver',{ref:"driver"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit($event)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Upload Picture"}},[_c('ValidationProvider',{attrs:{"vid":"picture","name":"Picture","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"placeholder":"Choose file to upload...","drop-placeholder":"Drop file here...","accept":"image/*","id":"input"},on:{"change":_vm.handleFileChange},model:{value:(_vm.driver_image),callback:function ($$v) {_vm.driver_image=$$v},expression:"driver_image"}}),_c('span',[_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":"Full Name"}},[_c('ValidationProvider',{attrs:{"name":"Full Name","vid":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('span',[_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":"Email Address"}},[_c('ValidationProvider',{attrs:{"name":"Email Address","vid":"email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('span',[_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":"Password"}},[_c('ValidationProvider',{attrs:{"name":"Password","rules":"required|min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"vid":"password","type":"password"},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_c('span',[_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":"Confirm Password"}},[_c('ValidationProvider',{attrs:{"name":"Confirm Password","vid":"confirm_password","rules":"required|min:8|confirmed:Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"password"},model:{value:(_vm.form.confirm_password),callback:function ($$v) {_vm.$set(_vm.form, "confirm_password", $$v)},expression:"form.confirm_password"}}),_c('span',[_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":"Address Line 1"}},[_c('ValidationProvider',{attrs:{"name":"Address Line 1","vid":"address_1","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.form.address_1),callback:function ($$v) {_vm.$set(_vm.form, "address_1", $$v)},expression:"form.address_1"}}),_c('span',[_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":"Address Line 2"}},[_c('ValidationProvider',{attrs:{"name":"Address Line 2","vid":"address_2","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.form.address_2),callback:function ($$v) {_vm.$set(_vm.form, "address_2", $$v)},expression:"form.address_2"}}),_c('span',[_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":"Select Branch"}},[_c('ValidationProvider',{attrs:{"name":"Branch","vid":"branch_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vSelect',{attrs:{"reduce":function (branches) { return branches.id; },"options":_vm.branches},model:{value:(_vm.form.branch_id),callback:function ($$v) {_vm.$set(_vm.form, "branch_id", $$v)},expression:"form.branch_id"}}),_c('span',[_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":"Unit No."}},[_c('ValidationProvider',{attrs:{"name":"Unit No.","vid":"unit_no","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.form.unit_no),callback:function ($$v) {_vm.$set(_vm.form, "unit_no", $$v)},expression:"form.unit_no"}}),_c('span',[_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":"Postcode"}},[_c('ValidationProvider',{attrs:{"name":"Postcode","vid":"post_code","rules":"required|digits:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number"},model:{value:(_vm.form.post_code),callback:function ($$v) {_vm.$set(_vm.form, "post_code", $$v)},expression:"form.post_code"}}),_c('span',[_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":"Select State"}},[_c('ValidationProvider',{attrs:{"name":"State","vid":"state","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vSelect',{attrs:{"reduce":function (states) { return states.state_name; },"options":_vm.states},model:{value:(_vm.form.state),callback:function ($$v) {_vm.$set(_vm.form, "state", $$v)},expression:"form.state"}}),_c('span',[_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":"Area"}},[_c('ValidationProvider',{attrs:{"name":"Area","vid":"area","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.form.area),callback:function ($$v) {_vm.$set(_vm.form, "area", $$v)},expression:"form.area"}}),_c('span',[_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":"Select Country"}},[_c('ValidationProvider',{attrs:{"name":"Country","vid":"country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vSelect',{attrs:{"reduce":function (countries) { return countries.label; },"options":_vm.countries},model:{value:(_vm.form.country),callback:function ($$v) {_vm.$set(_vm.form, "country", $$v)},expression:"form.country"}}),_c('span',[_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":"Phone No."}},[_c('ValidationProvider',{attrs:{"name":"Phone","vid":"phone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}}),_c('span',[_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}])})],1)],1)],1),_c('b-row',{staticClass:"px-2"},[(_vm.isLoading)?_c('b-button',[_c('b-spinner')],1):_c('b-button',{attrs:{"type":"submit","variant":"primary"}},[_vm._v(" Submit ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }