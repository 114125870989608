<template>
  <b-container fluid>
    <b-row class="mb-2">
      <b-col cols="5">
        <b-button
          variant="gradient-primary"
          class="mr-1"
          @click="showModal('add-driver')"
        >
          <span class=""> ADD DRIVER </span>
          <feather-icon
            icon="PlusCircleIcon"
            size="14"
            class="text-white ml-1"
          />
        </b-button>
      </b-col>
    </b-row>

    <b-card>
      <MyTable :columns="columns" :rows="driversList" :is-loading="isLoading">
        <template v-slot="{ props }">
          <div v-if="props.column.field === 'driver_branch'">
            <b-badge variant="primary" class="w-100 badge-padding">
              {{ props.row.driver_branch ? props.row.driver_branch.area : "" }}
            </b-badge>
          </div>

          <div v-else-if="props.column.field === 'name'">
            <b-avatar
              :src="
                props.row.driver && props.row.driver.driver_image
                  ? srcUrl + props.row.driver.driver_image
                  : ''
              "
              class="mr-2"
            />
            {{ props.row.name ? props.row.name : "" }}
          </div>

          <div v-else-if="props.column.field === 'actions'">
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item
                v-if="props.row.status.value !== 'process'"
                @click="showModal('edit-driver', props.row)"
              >
                <feather-icon icon="Edit2Icon" class="mr-50" />
                <span>Edit Driver</span>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="props.row.status.value !== 'process'"
                @click="handleDelete(props.row)"
              >
                <feather-icon icon="TrashIcon" class="mr-50" />
                <span>Delete Driver</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>

          <div v-else>
            {{
              props.formattedRow[props.column.field]
                ? props.formattedRow[props.column.field]
                : ""
            }}
          </div>
        </template>
      </MyTable>
    </b-card>

    <b-modal
      ref="modal"
      :title="modal.title"
      size="lg"
      no-close-on-backdrop
      hide-footer
    >
      <AddDriver
        v-if="modal.type === 'add-driver'"
        @driver-added="handlePostRequest"
      />
      <EditDriver
        v-if="modal.type === 'edit-driver'"
        :data="modal.data"
        @driver-edited="handlePostRequest"
      />
    </b-modal>
  </b-container>
</template>

<script>
import MyTable from "@/views/components/MyTable.vue";
import AddDriver from "@/views/pages/inside/Driver/AddDriver.vue";
import EditDriver from "@/views/pages/inside/Driver/EditDriver.vue";

export default {
  components: {
    MyTable,
    AddDriver,
    EditDriver,
  },

  data() {
    return {
      branch: [],
      rows: [],
      isLoading: false,
      srcUrl: `${this.$s3URL}/minidc/driver_photo/`,
      columns: [
        {
          label: "Driver ID",
          field: "id",
          tdClass: "text-center align-middle",
          thClass: "text-nowrap",
        },
        {
          label: "Driver Name",
          field: "name",
        },
        {
          label: "Registration Origin",
          field: "driver_branch",
          tdClass: "text-center align-middle",
        },
        {
          label: "Email",
          field: "email",
          tdClass: "text-center align-middle",
        },
        {
          label: "Phone Number",
          field: "phone",
          tdClass: "text-center align-middle",
        },
        {
          label: "Actions",
          field: "actions",
          tdClass: "text-center align-middle",
        },
      ],
      modal: {
        title: "",
        type: "",
        data: [],
      },
    };
  },
  computed: {
    driversList() {
      return [...this.rows].reverse();
    },
  },
  created() {
    this.getDrivers();
  },
  methods: {
    async getDrivers(refresh = false) {
      if (refresh === true) {
        this.rows = [];
      }
      this.isLoading = true;
      const response = await this.$http.get("driver");
      if (response.data.status) {
        this.rows = response.data.data;
      } else {
        this.$swal({
          title: "Error !",
          icon: "warning",
          text: response.data.message,
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "btn btn-danger",
          },
          buttonsStyling: false,
        });
      }
      this.isLoading = false;
    },

    showModal(type, data = []) {
      const title = type.replace("-", " ").toUpperCase();
      this.modal = {
        title,
        type,
        data,
      };
      this.$refs.modal.show();
    },

    handlePostRequest() {
      this.$refs.modal.hide();
      this.getDrivers(true);
    },

    handleDelete(row) {
      this.$swal({
        title: "Are you sure ?",
        text: `You are about to delete ${row.name}`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        reverseButtons: true,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger mr-2",
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          const response = await this.$http.delete(`driver/${row.id}`);
          if (response.data.status) {
            this.getDrivers();
            this.$swal({
              icon: "success",
              title: "Deleted!",
              text: response.data.message,
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
          } else {
            this.$swal({
              title: "Error !",
              icon: "warning",
              text: response.data.message,
              confirmButtonText: "OK",
              customClass: {
                confirmButton: "btn btn-danger",
              },
              buttonsStyling: false,
            });
          }
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "src/assets/scss/style.scss";
</style>
