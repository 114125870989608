<template>
  <b-overlay :show="isLoading">
    <b-row>
      <b-col cols="12" class="text-center">
        <b-avatar
          :src="uploaded ? uploaded : ''"
          variant="primary"
          alt="Avatar"
          style="width: 200px; height: 200px"
        />
      </b-col>
    </b-row>

    <ValidationObserver ref="driver">
      <b-form @submit.prevent="handleSubmit">
        <b-row>
          <b-col cols="12">
            <b-form-group label="Upload Picture">
              <ValidationProvider
                v-slot="{ errors }"
                vid="picture"
                name="Picture"
                rules="required"
              >
                <b-form-file
                  v-model="driver_image"
                  placeholder="Choose file to upload..."
                  drop-placeholder="Drop file here..."
                  accept="image/*"
                  @change="handleFileChange"
                  id="input"
                ></b-form-file>
                <span
                  ><small class="text-danger">{{ errors[0] }}</small></span
                >
              </ValidationProvider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" lg="6">
            <b-form-group label="Full Name">
              <ValidationProvider
                v-slot="{ errors }"
                name="Full Name"
                vid="name"
                rules="required"
              >
                <b-form-input v-model="form.name" />
                <span
                  ><small class="text-danger">{{ errors[0] }}</small></span
                >
              </ValidationProvider>
            </b-form-group>
          </b-col>

          <b-col cols="12" lg="6">
            <b-form-group label="Email Address">
              <ValidationProvider
                v-slot="{ errors }"
                name="Email Address"
                vid="email"
                rules="required"
              >
                <b-form-input v-model="form.email" />
                <span
                  ><small class="text-danger">{{ errors[0] }}</small></span
                >
              </ValidationProvider>
            </b-form-group>
          </b-col>

          <b-col cols="12" lg="6">
            <b-form-group label="Password">
              <ValidationProvider
                v-slot="{ errors }"
                name="Password"
                rules="required|min:8"
              >
                <b-form-input
                  v-model="form.password"
                  vid="password"
                  type="password"
                />
                <span
                  ><small class="text-danger">{{ errors[0] }}</small></span
                >
              </ValidationProvider>
            </b-form-group>
          </b-col>

          <b-col cols="12" lg="6">
            <b-form-group label="Confirm Password">
              <ValidationProvider
                v-slot="{ errors }"
                name="Confirm Password"
                vid="confirm_password"
                rules="required|min:8|confirmed:Password"
              >
                <b-form-input v-model="form.confirm_password" type="password" />
                <span
                  ><small class="text-danger">{{ errors[0] }}</small></span
                >
              </ValidationProvider>
            </b-form-group>
          </b-col>

          <b-col cols="12" lg="6">
            <b-form-group label="Address Line 1">
              <ValidationProvider
                v-slot="{ errors }"
                name="Address Line 1"
                vid="address_1"
                rules="required"
              >
                <b-form-input v-model="form.address_1" />
                <span
                  ><small class="text-danger">{{ errors[0] }}</small></span
                >
              </ValidationProvider>
            </b-form-group>
          </b-col>

          <b-col cols="12" lg="6">
            <b-form-group label="Address Line 2">
              <ValidationProvider
                v-slot="{ errors }"
                name="Address Line 2"
                vid="address_2"
                rules="required"
              >
                <b-form-input v-model="form.address_2" />
                <span
                  ><small class="text-danger">{{ errors[0] }}</small></span
                >
              </ValidationProvider>
            </b-form-group>
          </b-col>

          <b-col cols="12" lg="6">
            <b-form-group label="Select Branch">
              <ValidationProvider
                v-slot="{ errors }"
                name="Branch"
                vid="branch_id"
                rules="required"
              >
                <vSelect
                  v-model="form.branch_id"
                  :reduce="(branches) => branches.id"
                  :options="branches"
                />
                <span
                  ><small class="text-danger">{{ errors[0] }}</small></span
                >
              </ValidationProvider>
            </b-form-group>
          </b-col>

          <b-col cols="12" lg="6">
            <b-form-group label="Unit No.">
              <ValidationProvider
                v-slot="{ errors }"
                name="Unit No."
                vid="unit_no"
                rules="required"
              >
                <b-form-input v-model="form.unit_no" />
                <span
                  ><small class="text-danger">{{ errors[0] }}</small></span
                >
              </ValidationProvider>
            </b-form-group>
          </b-col>

          <b-col cols="12" lg="6">
            <b-form-group label="Postcode">
              <ValidationProvider
                v-slot="{ errors }"
                name="Postcode"
                vid="post_code"
                rules="required|digits:5"
              >
                <b-form-input v-model="form.post_code" type="number" />
                <span
                  ><small class="text-danger">{{ errors[0] }}</small></span
                >
              </ValidationProvider>
            </b-form-group>
          </b-col>

          <b-col cols="12" lg="6">
            <b-form-group label="Select State">
              <ValidationProvider
                v-slot="{ errors }"
                name="State"
                vid="state"
                rules="required"
              >
                <vSelect
                  v-model="form.state"
                  :reduce="(states) => states.state_name"
                  :options="states"
                />
                <span
                  ><small class="text-danger">{{ errors[0] }}</small></span
                >
              </ValidationProvider>
            </b-form-group>
          </b-col>

          <b-col cols="12" lg="6">
            <b-form-group label="Area">
              <ValidationProvider
                v-slot="{ errors }"
                name="Area"
                vid="area"
                rules="required"
              >
                <b-form-input v-model="form.area" />
                <span
                  ><small class="text-danger">{{ errors[0] }}</small></span
                >
              </ValidationProvider>
            </b-form-group>
          </b-col>

          <b-col cols="12" lg="6">
            <b-form-group label="Select Country">
              <ValidationProvider
                v-slot="{ errors }"
                name="Country"
                vid="country"
                rules="required"
              >
                <vSelect
                  v-model="form.country"
                  :reduce="(countries) => countries.label"
                  :options="countries"
                />
                <span
                  ><small class="text-danger">{{ errors[0] }}</small></span
                >
              </ValidationProvider>
            </b-form-group>
          </b-col>

          <b-col cols="12" lg="6">
            <b-form-group label="Phone No.">
              <ValidationProvider
                v-slot="{ errors }"
                name="Phone"
                vid="phone"
                rules="required"
              >
                <b-form-input v-model="form.phone" />
                <span
                  ><small class="text-danger">{{ errors[0] }}</small></span
                >
              </ValidationProvider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="px-2">
          <b-button v-if="isLoading">
            <b-spinner />
          </b-button>
          <b-button v-else type="submit" variant="primary"> Submit </b-button>
        </b-row>
      </b-form>
    </ValidationObserver>
  </b-overlay>
</template>
<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { success } from "@/common/SwalOptions";
import vSelect from "vue-select";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  data() {
    return {
      isLoading: false,
      required,
      branches: [],
      states: [],
      countries: [
        { id: 0, label: "Malaysia" },
        { id: 1, label: "Singapore" },
      ],
      uploaded: null,
      driver_image: null,
      form: {
        name: "",
        email: "",
        password: "",
        confirm_password: "",
        phone: "",
        address_1: "",
        address_2: "",
        branch_id: null,
        unit_no: null,
        post_code: null,
        area: "",
        state: "",
        country: "",
      },
    };
  },

  created() {
    this.preloadData();
  },

  methods: {
    async preloadData() {
      this.isLoading = true;
      await this.getAllBranches();
      await this.getAllStates();
      this.isLoading = false;
    },

    async getAllBranches() {
      const response = await this.$http.get("branch");
      if (response.data.status) {
        this.branches = response.data.data.map((items) => ({
          ...items,
          id: items.id,
          label: items.area,
        }));
      } else {
        this.$swal({
          title: "Error !",
          icon: "warning",
          text: response.data.message,
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "btn btn-danger",
          },
          buttonsStyling: false,
        });
      }
      this.isLoading = false;
    },

    async getAllStates() {
      const response = await this.$http.get("state");
      if (response.data.status) {
        this.states = response.data.data.map((items) => ({
          ...items,
          label: items.state_name,
        }));
      } else {
        this.$swal({
          title: "Error !",
          icon: "warning",
          text: response.data.message,
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "btn btn-danger",
          },
          buttonsStyling: false,
        });
      }
      this.isLoading = false;
    },

    async handleFileChange(e) {
      this.driver_image = e.target.files[0];
      if (!this.driver_image) {
        const errorMessage = "A Picture is required.";
        this.$refs.driver.setErrors({
          picture: errorMessage,
        });
        return;
      }

      this.$refs.driver.setErrors({ picture: "" });
      this.$swal(
        success({
          text: "You have successfully added a driver's picture!",
        })
      ).then((result) => {
        if (result.isConfirmed) {
          const reader = new FileReader();
          this.form.driver_image = this.driver_image;
          reader.onload = (evt) => {
            this.uploaded = evt.target.result;
          };
          reader.readAsDataURL(this.driver_image);
        }
      });
    },

    handleSubmit() {
      this.$refs.driver.validate().then((success) => {
        if (success) {
          this.isLoading = true;
          const payload = new FormData();
          /* eslint-disable-next-line */
          for (const key in this.form) {
            payload.append(key, this.form[key]);
          }
          this.$http.post("driver", payload).then((response) => {
            if (response.data.status) {
              this.$swal({
                title: "Success !",
                icon: "success",
                text: response.data.message,
                confirmButtonText: "OK",
                customClass: {
                  confirmButton: "btn btn-success",
                },
                buttonsStyling: false,
              }).then((result) => {
                if (result.isConfirmed) {
                  this.$emit("driver-added");
                }
              });
            } else {
              this.$refs.driver.setErrors(response.data.errors);
            }
            this.isLoading = false;
          });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
