<template>
  <b-overlay :show="isLoading">
    <b-row class="mb-4">
      <b-col cols="12" class="text-center">
        <b-avatar
          :src="uploaded"
          variant="primary"
          alt="Avatar"
          style="width: 200px; height: 200px"
        />
      </b-col>
    </b-row>

    <ValidationObserver ref="driver">
      <b-form @submit.prevent="handleSubmit(data.id)">
        <b-row>
          <b-col cols="12">
            <b-form-group label="Upload Picture">
              <ValidationProvider
                v-slot="{ errors }"
                vid="driver_image"
                name="Picture"
                rules="required|image"
              >
                <input
                  type="file"
                  accept="image/*"
                  class="form-control"
                  @change="handleFileChange"
                />
                <span
                  ><small class="text-danger">{{ errors[0] }}</small></span
                >
              </ValidationProvider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" lg="6">
            <b-form-group label="Full Name">
              <ValidationProvider
                v-slot="{ errors }"
                vid="name"
                name="Full Name"
                rules="required"
              >
                <b-form-input v-model="form.name" />
                <span
                  ><small class="text-danger">{{ errors[0] }}</small></span
                >
              </ValidationProvider>
            </b-form-group>
          </b-col>

          <b-col cols="12" lg="6">
            <b-form-group label="Email Address">
              <ValidationProvider
                v-slot="{ errors }"
                vid="email"
                name="Email Address"
                rules="required"
              >
                <b-form-input v-model="form.email" />
                <span
                  ><small class="text-danger">{{ errors[0] }}</small></span
                >
              </ValidationProvider>
            </b-form-group>
          </b-col>

          <b-col cols="12" lg="6">
            <b-form-group label="Address Line 1">
              <ValidationProvider
                v-slot="{ errors }"
                vid="address_1"
                name="Address Line 1"
                rules="required"
              >
                <b-form-input v-model="form.address_1" />
                <span
                  ><small class="text-danger">{{ errors[0] }}</small></span
                >
              </ValidationProvider>
            </b-form-group>
          </b-col>

          <b-col cols="12" lg="6">
            <b-form-group label="Address Line 2">
              <ValidationProvider
                v-slot="{ errors }"
                vid="address_2"
                name="Address Line 2"
                rules="required"
              >
                <b-form-input v-model="form.address_2" />
                <span
                  ><small class="text-danger">{{ errors[0] }}</small></span
                >
              </ValidationProvider>
            </b-form-group>
          </b-col>

          <b-col cols="12" lg="6">
            <b-form-group label="Select Branch">
              <ValidationProvider
                v-slot="{ errors }"
                vid="branch_id"
                name="Branch"
                rules="required"
              >
                <vSelect
                  v-model="form.branch_id"
                  :reduce="(branches) => branches.id"
                  :options="branches"
                />
                <span
                  ><small class="text-danger">{{ errors[0] }}</small></span
                >
              </ValidationProvider>
            </b-form-group>
          </b-col>

          <b-col cols="12" lg="6">
            <b-form-group label="Unit No.">
              <ValidationProvider
                v-slot="{ errors }"
                vid="unit_no"
                name="Unit No."
                rules="required"
              >
                <b-form-input v-model="form.unit_no" />
                <span
                  ><small class="text-danger">{{ errors[0] }}</small></span
                >
              </ValidationProvider>
            </b-form-group>
          </b-col>

          <b-col cols="12" lg="6">
            <b-form-group label="Postcode">
              <ValidationProvider
                v-slot="{ errors }"
                vid="post_code"
                name="Postcode"
                rules="required|digits:5"
              >
                <b-form-input v-model="form.post_code" type="number" />
                <span
                  ><small class="text-danger">{{ errors[0] }}</small></span
                >
              </ValidationProvider>
            </b-form-group>
          </b-col>

          <b-col cols="12" lg="6">
            <b-form-group label="Select State">
              <ValidationProvider
                v-slot="{ errors }"
                vid="state"
                name="State"
                rules="required"
              >
                <vSelect
                  v-model="form.state"
                  :reduce="(states) => states.state_name"
                  :options="states"
                />
                <span
                  ><small class="text-danger">{{ errors[0] }}</small></span
                >
              </ValidationProvider>
            </b-form-group>
          </b-col>

          <b-col cols="12" lg="6">
            <b-form-group label="Area">
              <ValidationProvider
                v-slot="{ errors }"
                vid="area"
                name="Area"
                rules="required"
              >
                <b-form-input v-model="form.area" />
                <span
                  ><small class="text-danger">{{ errors[0] }}</small></span
                >
              </ValidationProvider>
            </b-form-group>
          </b-col>

          <b-col cols="12" lg="6">
            <b-form-group label="Select Country">
              <ValidationProvider
                v-slot="{ errors }"
                vid="country"
                name="Country"
                rules="required"
              >
                <vSelect
                  v-model="form.country"
                  :reduce="(countries) => countries.label"
                  :options="countries"
                />
                <span
                  ><small class="text-danger">{{ errors[0] }}</small></span
                >
              </ValidationProvider>
            </b-form-group>
          </b-col>

          <b-col cols="12" lg="6">
            <b-form-group label="Phone No.">
              <ValidationProvider
                v-slot="{ errors }"
                vid="phone"
                name="Phone"
                rules="required"
              >
                <b-form-input v-model="form.phone" />
                <span
                  ><small class="text-danger">{{ errors[0] }}</small></span
                >
              </ValidationProvider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="px-2 py-2">
          <b-button v-if="isLoading">
            <b-spinner />
          </b-button>
          <b-button v-else type="submit" variant="primary"> Submit </b-button>
        </b-row>
      </b-form>
    </ValidationObserver>
  </b-overlay>
</template>
<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { success } from "@/common/SwalOptions";
import vSelect from "vue-select";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  props: {
    data: {
      required: true,
      type: [Object],
    },
  },
  data() {
    return {
      isLoading: false,
      srcUrl: `${this.$s3URL}/minidc/driver_photo/`,
      required,
      branches: [],
      states: [],
      countries: [
        { id: 0, label: "Malaysia" },
        { id: 1, label: "Singapore" },
      ],
      driver_image: null,
      uploaded: this.data.driver.driver_image
        ? `${this.$s3URL}/minidc/driver_photo/${this.data.driver.driver_image}`
        : "",
      form: {
        name: this.data.name,
        email: this.data.email,
        phone: this.data.phone,
        address_1: this.data.driver.address_1,
        address_2: this.data.driver.address_2,
        branch_id: this.data.driver_branch.id,
        unit_no: this.data.driver.unit_no,
        post_code: this.data.driver.post_code,
        area: this.data.driver.area,
        state: this.data.driver.state,
        country: this.data.driver.country,
        _method: "PUT",
      },
    };
  },

  created() {
    this.preloadData();
  },

  methods: {
    async preloadData() {
      this.isLoading = true;
      await this.getAllBranches();
      await this.getAllStates();
      this.isLoading = false;
    },

    async getAllBranches() {
      const response = await this.$http.get("branch");
      if (response.data.status) {
        this.branches = response.data.data.map((items) => ({
          ...items,
          id: items.id,
          label: items.area,
        }));
      } else {
        this.$swal({
          title: "Error !",
          icon: "warning",
          text: response.data.message,
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "btn btn-danger",
          },
          buttonsStyling: false,
        });
      }
      this.isLoading = false;
    },

    async getAllStates() {
      const response = await this.$http.get("state");
      if (response.data.status) {
        this.states = response.data.data.map((items) => ({
          ...items,
          label: items.state_name,
        }));
      } else {
        this.$swal({
          title: "Error !",
          icon: "warning",
          text: response.data.message,
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "btn btn-danger",
          },
          buttonsStyling: false,
        });
      }
      this.isLoading = false;
    },

    async handleFileChange(e) {
      const file = e.target.files[0];
      if (!file) {
        const errorMessage = "The Picture is required.";
        this.$refs.driver.setErrors({
          picture: errorMessage,
        });
        return;
      }

      this.$refs.driver.setErrors({ picture: "" });
      this.$swal(
        success({
          text: "You have successfully added a driver's picture!",
        })
      ).then((result) => {
        if (result.isConfirmed) {
          const reader = new FileReader();
          this.driver_image = file;
          reader.onload = (evt) => {
            this.uploaded = evt.target.result;
          };
          reader.readAsDataURL(file);
        }
      });
    },

    async handleSubmit(id) {
      this.isLoading = true;
      const payload = new FormData();
      const photo_payload = new FormData();
      let isValid = true;
      /* eslint-disable-next-line */
      for (const key in this.form) {
        payload.append(key, this.form[key]);
      }

      await this.$http.post(`driver/${id}`, payload).then((response) => {
        if (response.data.status) {
          const message = response.data.message;
          if (this.driver_image !== null) {
            photo_payload.append("driver_image", this.driver_image);
            photo_payload.append("user_id", id);
            this.$http
              .post(`upload_driver_photo`, photo_payload)
              .then((response) => {
                if (!response.data.status) {
                  isValid = false;
                  this.$refs.driver.setErrors(response.data.errors);
                }
              });
          }
          if (isValid) {
            this.$swal(
              success({
                text: message,
              })
            ).then(async (result) => {
              if (await result.isConfirmed) {
                this.isLoading = false;
                this.$emit("driver-edited");
              }
            });
          }
        } else {
          this.$refs.driver.setErrors(response.data.errors);
        }
      });
      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
